<template>
  <div class="container">
    <div class="page-title">
      <h3>控制台</h3>
    </div>

    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>资源</span>
      </div>
      <el-row class="row">
        <el-col :span="3">
          <router-link to="/device">
            <div class="mod">
              <div class="icon host">
                <i class="iconfont icon-zhuji"></i>
              </div>
              <p>主机</p>
            </div>
          </router-link>
        </el-col>
        <el-col :span="3">
          <router-link to="/app">
            <div class="mod">
              <div class="icon app">
                <i class="iconfont icon-groups"></i>
              </div>
              <p>应用</p>
            </div>
          </router-link>
        </el-col>
        <el-col :span="3">
          <router-link to="/group">
            <div class="mod">
              <div class="icon group">
                <i class="iconfont icon-jiqun"></i>
              </div>
              <p>集群</p>
            </div>
          </router-link>
        </el-col>
        <el-col :span="3">
          <router-link to="/room">
            <div class="mod">
              <div class="icon room">
                <i class="iconfont icon-jigui"></i>
              </div>
              <p>机房和机柜</p>
            </div>
          </router-link>
        </el-col>
        <el-col :span="3">
          <router-link to="/safe?type=7">
            <div class="mod">
              <div class="icon firewall">
                <i class="iconfont icon-safe"></i>
              </div>
              <p>防火墙</p>
            </div>
          </router-link>
        </el-col>
        <el-col :span="3">
          <router-link to="/network?type=8">
            <div class="mod">
              <div class="icon exchanger">
                <i class="iconfont icon-jiaohuanji"></i>
              </div>
              <p>交换机</p>
            </div>
          </router-link>
        </el-col>
        <el-col :span="3">
          <router-link to="/other">
            <div class="mod">
              <div class="icon other">
                <i class="iconfont icon-dashuju"></i>
              </div>
              <p>其他设备</p>
            </div>
          </router-link>
        </el-col>
        <el-col :span="3">
          <router-link to="/report">
            <div class="mod">
              <div class="icon report">
                <i class="iconfont icon-anquan"></i>
              </div>
              <p>安全报告</p>
            </div>
          </router-link>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>统计</span>
      </div>
      <div class="total-info">
        <el-row class="row" :gutter="20">
          <el-col :span="6">
            <el-row class="item">
              <el-col :span="6" class="item-name">
                <el-link type="primary" href="/#/device" class="link">{{sum.host.total}}</el-link>
                <p>主机</p>
              </el-col>
              <el-col :span="18">
                <ul>
                  <li v-for="(item,index) in sum.host.sub" :key="index">
                    {{item.name}}：
                    <el-link type="primary" :href="'/#/device?type_id='+item.id" class="link">{{item.total}}</el-link>
                    台
                  </li>
                </ul>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="9">
            <el-row class="item">
              <el-col :span="4" class="item-name">
                <el-link type="primary" href="/#/device" class="link">{{sum.network.total}}</el-link>
                <p>网络设备</p>
              </el-col>
              <el-col :span="20">
                <ul>
                  <li v-for="(item,index) in sum.network.sub" :key="index">
                    {{item.name}}：
                    <el-link type="primary" :href="'/#/network?type_id='+item.id" class="link">{{item.total}}</el-link>
                    台
                  </li>
                </ul>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="9">
            <el-row class="item">
              <el-col :span="4" class="item-name">
                <el-link type="primary" href="/#/other" class="link">{{sum.other.total}}</el-link>
                <p>其他设备</p>
              </el-col>
              <el-col :span="20">
                <ul>
                  <li v-for="(item,index) in sum.other.sub" :key="index">
                    {{item.name}}：
                    <el-link type="primary" :href="'/#/other?type_id='+item.id" class="link">{{item.total}}</el-link>
                    台
                  </li>
                </ul>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="18">
            <el-row class="item safe-item">
              <el-col :span="2" class="item-name">
                <el-link type="primary" href="/#/safe" class="link">{{sum.safe.total}}</el-link>
                <p>安全设备</p>
              </el-col>
              <el-col :span="22">
                <ul>
                  <li v-for="(item,index) in sum.safe.sub" :key="index">
                    {{item.name}}：
                    <el-link type="primary" :href="'/#/safe?type_id='+item.id" class="link">{{item.total}}</el-link>
                    台
                  </li>
                </ul>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row class="item">
              <el-col :span="6" class="item-name">
                <span class="success" v-if="firewall.percent>80">{{firewall.percent}}%</span>
                <span class="warning" v-else>{{firewall.percent}}%</span>
                <p>安全覆盖</p>
              </el-col>
              <el-col :span="18" class="firewall-info">
                <p>已被防火墙覆盖：<span>{{firewall.cover}}</span>台</p>
                <p>未被防火墙覆盖：<span>{{firewall.no_cover}}</span>台</p>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>关系图</span>
      </div>
      <hw-device-chart ref="devmap" :type="0"></hw-device-chart>
    </el-card>
  </div>
</template>

<script>

  import DeviceChart from "../components/pages/DeviceChart";

  export default {
    name: "Main",
    components: {
      hwDeviceChart: DeviceChart,
    },
    data() {
      return {
        sum: {
          host: {},
          safe: {},
          other: {}
        },
        firewall: {},
      }
    },

    mounted() {

    },
    created() {
      this.getDeviceTotal();
      this.getFirewallCover();
    },
    methods: {
      //设备统计信息
      getDeviceTotal() {
        this.$get(this.$api.GetTotalInfo).then((res) => {
            this.sum = res.data;
        });
      },
      //防火墙覆盖统计
      getFirewallCover() {
        this.$get(this.$api.GetFirewallCover).then((res) => {
            this.firewall = res.data;
        });
      },
    }
  }
</script>

<style scoped lang="less">

  .box-card {
    position: relative;
    margin: 10px;

    .mod {
      text-align: center;

      .icon {
        width: 60px;
        height: 50px;
        padding: 10px;
        border-radius: 20px;
        margin: 10px auto 4px auto;
        background: #218BC3;
        color: #fff;
        text-align: center;

        i {
          font-size: 46px;
        }
      }

      .host {
        background-color: #5470C6; //#F15B41;
      }

      .app {
        background-color: #91CC75; //#984EF3;
      }

      .group {
        background-color: #FAC858; //#F9B955;
      }

      .room {
        background-color: #EE6666; //#3FACC3;
      }

      .firewall {
        background-color: #73c0de; //#F9B955;
      }

      .exchanger {
        background-color: #3BA272; //#4A8EF4;
      }

      .other {
        background-color: #66B1FF;
      }

      p {
        padding: 6px;
      }
    }
  }

  .tab-wrap {
    margin: 10px;
  }

  .total-info {
    padding: 0px;

    .item {
      border: 1px solid #ddd;
      margin-bottom: 20px;
      padding: 10px;
      .item-name {
        line-height: 32px;
        text-align: center;
        border-right: 1px solid #ddd;
        .link {
          font-size: 20px;
        }
        span {
          font-size: 20px;
        }
        span.warning {
          color: #f60;
        }

        span.success {
          color: #3BA272;
        }
      }

      .firewall-info {
        line-height: 32px;
        padding-left: 10px;
        font-size: 15px;

        span {
          color: #1E75BD;
        }
      }

      ul {
        list-style: none;
        padding-left: 10px;

        li {
          width: 220px;
          color: #666;
          font-size: 15px;
          float: left;

          .link {
            font-size: 15px;
          }
        }
      }
    }

  }

  /deep/ .el-card__header {
    padding: 10px;
  }
</style>
